<template>
  <eagle-form :form-key="formKey"> </eagle-form>
</template>

<script lang="babel" type="text/babel">
import formMixin from '@/components/form/mixins/formMixin'
import formConfig from './providerInfoReadonly'
export default {
  mixins: [formMixin],
  components: {

  },
  data: () => ({
    formKey: 'provider-info--readonly-form',
    meta: {},
  }),
  computed: {
    isMainManager() {
      return this.$store.getters['member/isProviderMainManager']
    },
    cooperateProviderId() {
      return this.$route.params.cooperateProviderId
    },
    storeId() {
      return this.$store.getters[`member/storeId`]
    },
    memberId() {
      return this.$store.getters[`token/memberId`]
    },
  },
  methods: {
    async beforeFormInit() {
      await Promise.all([this.getStatus()])
    },
    validateBeforeSave() {
      // TODO
    },
    async getStatus() {
      const res = await this.$api.collection.storeApi.readByProviderAndStore(this.storeId, this.cooperateProviderId)
      this.meta.pivot = res.pivot
    },
    afterSaveSuccessCallback(result) {

    },
    async readApi(target) {
      const res =  await this.$api.collection.providerApi.read(this.cooperateProviderId)
      res.pivot = this.meta.pivot
      this.$emit('setFormKey', this.formKey)
      return res
    },
    async updateApi(target, formData) {
      return await this.$api.collection.providerApi.update(this.cooperateProviderId, formData)
    },
    getFormConfig() {
      return formConfig
    },
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>
